import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Avatar from "@material-ui/core/Avatar";

//atoms
// import Avatar from "2-atoms/avatar";
import { IAuthContextPerson } from "1-quarks/context/User";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/3-molecules/navUser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    menuButton: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    name: {
      flexGrow: 1,
      display: "inline",
    },
    avatar: {},
    menu: {},
    iconButton: {},
  })
);

export interface INavUserProps {
  /** List of menu items to pass to NavLink */
  user?: IAuthContextPerson | null;

  /** Logout button click event */
  onLogoutClick?: () => void;
}

const NavUser = ({ user, onLogoutClick }: INavUserProps) => {
  st.run({ type: "start", path });

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (user === undefined || user === null) {
    return <React.Fragment>&nbsp;</React.Fragment>;
  }
  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.name}>
        {user?.displayName}
      </Typography>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <Avatar
          className={classes.avatar}
          src={user?.photoUrl}
          alt={user?.displayName}
        />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
        className={classes.menu}
      >
        <MenuItem onClick={onLogoutClick} className={classes.menuButton}>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default NavUser;
