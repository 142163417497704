import React from "react";

import st from "../1-quarks/sneaky-ton";
const path = "/6-pages/loading";

const Loading = () => {
  st.run({ type: "start", path });
  return <div>404</div>;
};

export default Loading;
