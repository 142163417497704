// This component will decide whether this page is still loading or not
// based on whether authentication has been initialized

import React, { useEffect } from "react";

//component
import AppRouter from "./AppRouter";
import LoadingPage from "6-pages/loading";

//context
import useUserContextSession, {
  useAuthContextIsInitialised,
  useAuthContextCurrentUser,
  helperTradesSubscribe,
  helperTradesUnsubscribe,
} from "1-quarks/context/User";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/1-quarks/routers/App";

const App = () => {
  const auth = useAuthContextCurrentUser();
  const { dispatchUserContext } = useUserContextSession();
  st.run({ type: "start", path });

  //setup listener
  useEffect(() => {
    helperTradesSubscribe(dispatchUserContext, auth);
    return () => helperTradesUnsubscribe(dispatchUserContext, auth);
  }, [auth, dispatchUserContext]);

  if (!useAuthContextIsInitialised()) {
    st.run({ type: "rtn", path, fn: "App", msg: "not initialised" });
    return <LoadingPage />;
  }

  st.run({ type: "rtn", path, fn: "App", msg: "initialised" });
  return <AppRouter />;
};

export default App;
