import { ITags } from "0-bonds/interface/tag";
// const path = "/1-quark/context/User/action-tags";

export interface ITagsContextActionSet {
  type: "TAGS_SET";
  tags: ITags;
}
export const TagsContextActionSet = (tags: ITags): ITagsContextActionSet => {
  return {
    type: "TAGS_SET",
    tags,
  };
};

export interface ITagsContextActionAdd {
  type: "TAGS_ADD";
  tags: ITags;
}
export const TagsContextActionAdd = (tags: ITags): ITagsContextActionAdd => {
  return {
    type: "TAGS_ADD",
    tags,
  };
};

export interface ITagsContextActionDel {
  type: "TAGS_DEL";
  tagName: string;
}
export const TagsContextActionDel = (
  tagName: string
): ITagsContextActionDel => {
  return {
    type: "TAGS_DEL",
    tagName,
  };
};
