import { ISymbols } from "0-bonds/interface/symbol";
// const path = "/1-quark/context/User/action-symbols";

export interface ISymbolsContextActionSet {
  type: "SYMBOLS_SET";
  symbols: ISymbols;
}
export const SymbolsContextActionSet = (
  symbols: ISymbols
): ISymbolsContextActionSet => {
  return {
    type: "SYMBOLS_SET",
    symbols,
  };
};

export interface ISymbolsContextActionAdd {
  type: "SYMBOLS_ADD";
  symbols: ISymbols;
}
export const SymbolsContextActionAdd = (
  symbols: ISymbols
): ISymbolsContextActionAdd => {
  return {
    type: "SYMBOLS_ADD",
    symbols,
  };
};

export interface ISymbolsContextActionDel {
  type: "SYMBOLS_DEL";
  symbolName: string;
}
export const SymbolsContextActionDel = (
  symbolName: string
): ISymbolsContextActionDel => {
  return {
    type: "SYMBOLS_DEL",
    symbolName,
  };
};
