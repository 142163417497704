export default [
  {
    title: "Dashboard",
    to: "/dashboard",
  },
  {
    title: "Add Trade",
    to: "/trade/add",
  },
  {
    title: "List Trade",
    to: "/trade",
  },
];
