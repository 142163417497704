import React from "react";
import { useHistory } from "react-router-dom";

import TradeAddTemplate from "5-templates/TradeAdd";
import TradeAddFormData from "0-bonds/data/TradeForm";
import {
  useAuthContextCurrentUser,
  useTagContextGet,
  useSymbolContextGet,
} from "1-quarks/context/User";
import { ITradeNewProps, defaultTradeNew } from "0-bonds/interface/trade";
import { helperTradesAdd } from "1-quarks/context/User";
//logging
import st from "1-quarks/sneaky-ton";
const path = "/6-pages/TradeAdd";

const TradeAddPage = () => {
  st.run({ type: "start", path, fn: "main" });
  const auth = useAuthContextCurrentUser();
  const tags = useTagContextGet();
  const symbols = useSymbolContextGet();

  const history = useHistory();

  const data = React.useRef<ITradeNewProps>(defaultTradeNew);

  const onSubmit = async () => {
    st.run({ type: "start", path, fn: "main/onSubmit" });

    helperTradesAdd(auth, data.current);
    history.push("/trade");
  };

  return (
    <TradeAddTemplate
      headerTitle="Add trade"
      formJson={TradeAddFormData(
        Object.keys(symbols),
        Object.keys(tags),
        onSubmit
      )}
      defaultValues={data.current}
      onSubmit={onSubmit}
      onChange={(k, v) => {
        data.current = { ...data.current, [k]: v };
      }}
    />
  );
};
export default TradeAddPage;
