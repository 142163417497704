import React from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";

import { StandardFormInputProps } from "0-bonds/interface/StandardFormComponent";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/3-molecules/FormSelect";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "5px 0",
      width: "100%",
    },
    text: {},
    inputRoot: { height: 38 },
  })
);
export interface IFormSelectOptionType {
  label: string;
  value: string;
}
const filter = createFilterOptions<IFormSelectOptionType>();
const strToOptions = (
  s: IFormSelectOptionType | string
): IFormSelectOptionType => {
  if (typeof s === "string") return { label: s, value: s };
  return s;
};
const optToStr = (o: IFormSelectOptionType | string): string => {
  if (typeof o === "string") return o;
  return o.value;
};

const toOptions = (
  s: IFormSelectOptionType | string | IFormSelectOptionType[] | string[]
) => {
  if (Array.isArray(s)) return (s as any[]).map(strToOptions);
  return strToOptions(s);
};

const toStr = (
  s: IFormSelectOptionType | string | IFormSelectOptionType[] | string[]
) => {
  if (Array.isArray(s)) return (s as any[]).map(optToStr);
  return optToStr(s);
};

export interface IFormSelectProps
  extends Omit<StandardFormInputProps, "required"> {
  defaultValue?: string[];
  options: string[];
  label?: string;
  placeholder?: string;

  // autoComplete?: boolean;
  // autoHighlight?: boolean;
  // autoSelect?: boolean;
  // disabled?: boolean;
  creatable?: boolean;

  freeSolo?: boolean;
  fullWidth?: boolean;
  multiple?: boolean;

  selectOnFocus?: boolean;
  clearOnBlur?: boolean;
  handleHomeEndKeys?: boolean;
  filterSelectedOptions?: boolean;
  // filterSelectedOptions?: boolean;

  /** Change event */
  onChangeValue?: (value: any | any[], reason: string) => void;
}

const prefix = "Add ";

const FormSelect = ({ style, ...props }: IFormSelectProps) => {
  st.run({ type: "start", path });
  const [value, setValue] = React.useState<
    IFormSelectOptionType | IFormSelectOptionType[] | null
  >(props.defaultValue ? toOptions(props.defaultValue) : null);

  const classes = useStyles();

  const selectProps = {
    value: value,
    options: toOptions(props.options) as IFormSelectOptionType[],
    multiple: props.multiple,
    disabled: props.disabled,
    fullWidth: props.fullWidth,
    freeSolo: props.freeSolo,
    selectOnFocus: props.selectOnFocus,
    clearOnBlur: props.clearOnBlur,
    handleHomeEndKeys: props.handleHomeEndKeys,
    filterSelectedOptions: props.filterSelectedOptions,
    getOptionLabel: (option: IFormSelectOptionType) => {
      // Value selected with enter, right from the input
      if (typeof option === "string") {
        return option;
      }
      return option.value;
    },
    getOptionSelected: (
      option: IFormSelectOptionType,
      value: IFormSelectOptionType
    ) => {
      return option.value === value.value;
    },
    ...(props.creatable && {
      filterOptions: (options: any, params: any) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== "") {
          filtered.push({
            label: prefix + params.inputValue,
            value: params.inputValue,
          });
        }

        return filtered;
      },
    }),
    onChange: (event: object, value: any | any[], reason: string) => {
      const convertedOpts = toOptions(value);
      if (props.onChangeValue)
        props.onChangeValue(toStr(convertedOpts), reason);
      setValue(convertedOpts);
    },
  };

  return (
    <FormGroup className={classes.root} style={style}>
      <Autocomplete
        {...selectProps}
        renderTags={(value: IFormSelectOptionType[], getTagProps: any) => {
          return value.map((option: IFormSelectOptionType, index: number) => (
            <Chip
              variant="default"
              label={option.value}
              {...getTagProps({ index })}
            />
          ));
        }}
        renderOption={(option: IFormSelectOptionType) => option.label}
        renderInput={(params: TextFieldProps) => (
          <TextField
            {...params}
            variant="standard"
            label={props.label}
            placeholder={props.placeholder}
          />
        )}
        classes={{ inputRoot: classes.inputRoot }}
      />
    </FormGroup>
  );
};

export default FormSelect;
