import React from "react";

import HeaderOrganism from "4-organisms/header";

import { useMenuContextGet } from "1-quarks/context/Global";
import { useAuthContextCurrentUser } from "1-quarks/context/User";
import { doSignOut } from "1-quarks/firebase";

import st from "1-quarks/sneaky-ton";
const path = "/6-pages/components/header";

export interface IHeaderCompProps {
  headerTitle: string;
}
const HeaderComp = (props: IHeaderCompProps) => {
  st.run({ type: "start", path });
  const menu = useMenuContextGet();
  const auth = useAuthContextCurrentUser();
  return (
    <HeaderOrganism
      headerTitle={props.headerTitle}
      menu={menu}
      user={auth}
      onLogoutClick={doSignOut}
    />
  );
};

export default HeaderComp;
