import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";

import { StandardFormInputProps } from "0-bonds/interface/StandardFormComponent";
//helper
import st from "1-quarks/sneaky-ton";
const path = "/3-molecules/FormLabelText";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "5px 0",
      width: "100%",
    },
    text: {},
  })
);

export interface IFormDateTimeProps extends StandardFormInputProps {
  defaultValue?: string | number;

  error?: boolean;
  type?: "datetime" | "date" | "time";

  //text
  label?: string;
  helperText?: string;

  fullWidth?: boolean;

  onChangeValue?: (e: any) => void;
  max?: string;
  min?: string;
}

const FormDateTime = ({
  type = "datetime",
  onChangeValue,
  style,
  ...props
}: IFormDateTimeProps) => {
  st.run({ type: "start", path });

  const classes = useStyles();

  return (
    <FormGroup className={classes.root} style={style}>
      <TextField
        type={type === "datetime" ? "datetime-local" : type}
        className={classes.text}
        onChange={(e) => {
          if (onChangeValue) onChangeValue(e.target.value);
        }}
        {...props}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          min: props.min,
          max: props.max,
          pattern: "[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}",
        }}
      />
    </FormGroup>
  );
};

export default FormDateTime;
