import useUserContextSession from ".";
import { ITags } from "0-bonds/interface/tag";
import { IUserContextDispatch } from "./interface";
import {
  TagsContextActionAdd,
  TagsContextActionDel,
  TagsContextActionSet,
} from "./action-tags";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/1-quarks/context/User/helper-tags";

export const useTagContextGet = () => {
  st.run({ type: "start", path, fn: "useTagContextGet" });
  const { UserContext } = useUserContextSession();
  if (!("tags" in UserContext)) return {};

  return UserContext["tags"];
};

export const helperTagContextSet = (
  dispatchUserContext: IUserContextDispatch,
  tag: ITags
) => {
  dispatchUserContext(TagsContextActionSet(tag));
};

export const helperTagContextAdd = (
  dispatchUserContext: IUserContextDispatch,
  tag: ITags
) => {
  dispatchUserContext(TagsContextActionAdd(tag));
};

export const helperTagContextDel = (
  dispatchUserContext: IUserContextDispatch,
  tagName: string
) => {
  dispatchUserContext(TagsContextActionDel(tagName));
};
