import React from "react";
import LoadingTemplate from "5-templates/loading";
import st from "../1-quarks/sneaky-ton";
const path = "/6-pages/loading";

const LoadingPage = () => {
  st.run({ type: "start", path });
  return <LoadingTemplate>loading</LoadingTemplate>;
};

export default LoadingPage;
