import fireapp from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "firebase/auth";
import * as firebaseUi from "firebaseui";

//helper
import { AesDecrypt } from "./encrypt";

import st from "./sneaky-ton";
const path = "/1-quarks/firebase";

const config = JSON.parse(
  AesDecrypt(process.env.REACT_APP_FIREBASE_ENCRYPT_CONFIG) || "{}"
);

fireapp.initializeApp(config);

const fireDb = fireapp.database();
const fireStore = fireapp.firestore();
const fireAuth = fireapp.auth();
const googleAuthProvider = new fireapp.auth.GoogleAuthProvider();

export function doSignupEmail(
  email: string,
  password: string,
  onAccepted?: (value: fireapp.auth.UserCredential | undefined) => any,
  onRejected?: (reason: any) => PromiseLike<never>
) {
  st.run({ type: "start", path, fn: "doSignupEmail" });
  fireAuth
    .createUserWithEmailAndPassword(email, password)
    .then(onAccepted, onRejected);
}

export function doSigninEmail(
  email: string,
  password: string,
  onAccepted?: (value: fireapp.auth.UserCredential | undefined) => any,
  onRejected?: (reason: any) => PromiseLike<never>
) {
  st.run({ type: "start", path, fn: "doSigninEmail" });
  fireAuth
    .signInWithEmailAndPassword(email, password)
    .then(onAccepted, onRejected);
}

export function doForgotPasswordEmail(
  email: string,
  onAccepted?: () => any,
  onRejected?: (reason: any) => PromiseLike<never>
) {
  st.run({ type: "start", path, fn: "doForgotPasswordEmail" });
  fireAuth.sendPasswordResetEmail(email).then(onAccepted, onRejected);
}

export function doSigninGoogle() {
  st.run({ type: "start", path, fn: "doSigninGoogle" });
  fireAuth.signInWithPopup(googleAuthProvider);
}

export function doSignOut() {
  st.run({ type: "start", path, fn: "doSignOut" });
  fireAuth.signOut();
}

export { fireapp, googleAuthProvider, fireStore, fireDb, fireAuth, firebaseUi };
