import React from "react";

import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { firebaseUi } from "1-quarks/firebase";
import FirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/5-templates/login";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "90vh",
      padding: "10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      alignContent: "center",
    },
    title: {},
    dialog: {},
  })
);

export interface ILoginProps {
  pageTitle: string;
  firebaseAuthUiConfig: firebaseUi.auth.Config;
  firebaseAuth: firebase.auth.Auth;
}

const LoginTemplate = (props: ILoginProps) => {
  st.run({ type: "start", path });

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        Welcome to {props.pageTitle}
      </Typography>
      <div className={classes.dialog}>
        <FirebaseAuth
          uiConfig={props.firebaseAuthUiConfig}
          firebaseAuth={props.firebaseAuth}
        />
      </div>
    </div>
  );
};

export default LoginTemplate;
