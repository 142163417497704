import React from "react";
import { useHistory, useParams } from "react-router-dom";

import TradeEditTemplate from "5-templates/TradeEdit";
import TradeAddFormData from "0-bonds/data/TradeForm";
import {
  useTagContextGet,
  useSymbolContextGet,
  useAuthContextCurrentUser,
} from "1-quarks/context/User";
import { ITradeLocalProps, defaultTradeLocal } from "0-bonds/interface/trade";
//logging
import st from "1-quarks/sneaky-ton";
import {
  useTradeContextGetTrade,
  helperTradesEdit,
} from "1-quarks/context/User/helper-trades";
const path = "/6-pages/TradeEdit";

const TradeEditPage = () => {
  st.run({ type: "start", path, fn: "main" });
  const { id } = useParams();
  const tags = useTagContextGet();
  const symbols = useSymbolContextGet();
  const trade = useTradeContextGetTrade(id);
  const auth = useAuthContextCurrentUser();

  const history = useHistory();

  const data = React.useRef<ITradeLocalProps>(trade || defaultTradeLocal);

  const onSubmit = async () => {
    st.run({ type: "start", path, fn: "main/onSubmit" });

    helperTradesEdit(auth, id, data.current);
    history.push("/trade");
  };

  return (
    <TradeEditTemplate
      headerTitle="Edit trade"
      formJson={TradeAddFormData(
        Object.keys(symbols),
        Object.keys(tags),
        onSubmit
      )}
      defaultValues={data.current}
      onSubmit={onSubmit}
      isNotFound={trade === undefined}
      onChange={(k, v) => {
        data.current = { ...data.current, [k]: v };
      }}
    />
  );
};
export default TradeEditPage;
