//local

import { IAuthContextPerson, ITradeContextTradesProp } from "./context";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/1-quark/context/User/action";

export interface IAuthContextActionSet extends IAuthContextPerson {
  type: "AUTH_SET";
}
export const AuthContextActionSet = (
  user: IAuthContextPerson
): IAuthContextActionSet => {
  st.run({ type: "start", path, fn: "AuthContextActionSet" });
  return {
    type: "AUTH_SET",
    ...user,
  };
};

export interface IAuthContextActionDel {
  type: "AUTH_DEL";
}
export const AuthContextActionDel = (): IAuthContextActionDel => {
  st.run({ type: "start", path, fn: "AuthContextActionDel" });
  return {
    type: "AUTH_DEL",
  };
};

export interface ITradesContextActionSet {
  type: "TRADES_SET";
  trades: ITradeContextTradesProp;
}
export const TradesContextActionSet = (
  trades: ITradeContextTradesProp
): ITradesContextActionSet => {
  return {
    type: "TRADES_SET",
    trades,
  };
};
