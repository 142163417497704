import React from "react";
import { Route, Redirect } from "react-router-dom";

//interface
import { IRoute } from "./AppRouter";

import st from "1-quarks/sneaky-ton";
const path1 = "/1-quarks/routers/myRoute";

export interface IMyRouteProps extends IRoute {
  isAuthenticated: boolean;
}

const MyRoute = ({
  title,
  path,
  component,
  exact,
  urlIfAuthenticated,
  urlNotAuthenticated,
  isAuthenticated,
}: IMyRouteProps) => {
  st.run({ type: "start", path1 });
  if (isAuthenticated && urlIfAuthenticated !== undefined) {
    //if authenticated url exists and user is authenticated
    return <Redirect to={urlIfAuthenticated} push />;
  }

  if (!isAuthenticated && urlNotAuthenticated !== undefined) {
    //if not authenticated url exists and user is not authenticated
    return <Redirect to={urlNotAuthenticated} push />;
  }
  return <Route path={path} component={component} exact={exact} />;
};

export default MyRoute;
