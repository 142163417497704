import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  TableContainer,
  Table,
  TablePagination,
} from "@material-ui/core";

import TableHeaderMolecule, {
  ITradeTableHeaderColumnProps,
  TradeTableHeaderStrToColumn,
} from "3-molecules/TradeTableHeader";
import TableBodyMolecule from "3-molecules/TradeTableBody";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/4-organisms/Table";

const useStyles = makeStyles({
  root: {},
  table: {},
});

export interface ITradeTableProps<T> {
  data: T[];
  /** columns to be displayed in header */
  columns?: ITradeTableHeaderColumnProps[];
  onRowClickedWithId: (id: string) => void;
}

const TradeTable = <T extends { [key: string]: any }>(
  props: ITradeTableProps<T>
) => {
  st.run({ type: "start", path });
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  if (Object.keys(props.data).length === 0) {
    return <div>No data available</div>;
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const columns: ITradeTableHeaderColumnProps[] =
    props.columns !== undefined
      ? props.columns
      : Object.keys(props.data[0]).map(TradeTableHeaderStrToColumn);
  return (
    <React.Fragment>
      <TableContainer className={classes.root} component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHeaderMolecule columns={columns} />
          <TableBodyMolecule
            columns={columns}
            data={props.data}
            rowsPerPage={rowsPerPage}
            page={page}
            onRowClickedWithId={props.onRowClickedWithId}
          />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 25, 50]}
        component="div"
        count={props.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </React.Fragment>
  );
};

export default TradeTable;
