import React from "react";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";

import { StandardFormInputProps } from "0-bonds/interface/StandardFormComponent";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/3-molecules/FormSwitch";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "5px 0",
      width: "100%",
    },
    text: {},
  })
);
const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch);

export interface IFormSwitchProps extends StandardFormInputProps {
  /** Label on left side of the switch */
  labelLeft?: string;
  /** Label on right side of the switch */
  labelRight?: string;

  /////switch props
  /** determine whether it is checked or not */
  defaultValue?: boolean;

  /** Change event */
  onChangeValue?: (v: boolean) => void;
}

const FormSwitch = ({
  labelLeft,
  labelRight,
  defaultValue,
  id,
  name,
  required,
  disabled,
  onChangeValue,
  style,
  ...props
}: IFormSwitchProps) => {
  st.run({ type: "start", path });
  const [checked, setChecked] = React.useState(defaultValue);

  const classes = useStyles();
  const switchProps = {
    id,
    name,
    required,
    disabled,
    checked,
    onChange: () => {
      if (onChangeValue) onChangeValue(!checked);
      setChecked(!checked);
    },
  };
  return (
    <FormGroup className={classes.root} style={style}>
      <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>{labelLeft}</Grid>
          <Grid item>
            <AntSwitch {...switchProps} />
          </Grid>
          <Grid item>{labelRight}</Grid>
        </Grid>
      </Typography>
    </FormGroup>
  );
};

export default FormSwitch;
