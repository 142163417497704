import React from "react";
import { makeStyles } from "@material-ui/core/styles";

//helper
import st from "1-quarks/sneaky-ton";
import FormSelect from "3-molecules/FormSelect";
const path = "/4-organisms/FilterBar";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  search: { width: "48%", minWidth: "200px" },
});

export interface IFilterBarProps {
  defaultInclude?: string[];
  defaultExclude?: string[];
  tags: string[];
  onIncludeValue?: (value: any | any[], reason: string) => void;
  onExcludeValue?: (value: any | any[], reason: string) => void;
}

const FilterBar = (props: IFilterBarProps) => {
  st.run({ type: "start", path });
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.search}>
        <FormSelect
          options={props.tags}
          multiple={true}
          defaultValue={props.defaultInclude}
          label="Include tags"
          placeholder="Include trade for analysis"
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          filterSelectedOptions
          onChangeValue={props.onIncludeValue}
        />
      </div>

      <div className={classes.search}>
        <FormSelect
          options={props.tags}
          multiple={true}
          defaultValue={props.defaultExclude}
          label="Exclude tags"
          placeholder="Exclude trade from analysis"
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          filterSelectedOptions
          onChangeValue={props.onExcludeValue}
        />
      </div>
    </div>
  );
};

export default FilterBar;
