import { ITradeLocalProps } from "0-bonds/interface/trade";
import { ITags } from "0-bonds/interface/tag";
import { objectHasMatchProperty } from "./object";

export const tradeIsOpen = (trade: ITradeLocalProps): boolean => {
  return (trade.closePrice || 0) <= 0;
};

export const tradeContainsTag = (
  trade: ITradeLocalProps,
  tags: ITags
): boolean => {
  return objectHasMatchProperty(trade.tags, tags);
};
