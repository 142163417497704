// This component is responsible for the actual routing
import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

//route functions
import MyRoute from "./myRoute";

//context
import { useAuthContextIsLoggedOn } from "1-quarks/context/User";
import routeData from "0-bonds/data/Route";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/1-quarks/routers/AppRouter";

export interface IRoute {
  title?: string; //string to display in menu, will not show in menu if missing

  /** The path to be used by route. The key has to be kept to path otherwise it won't work. Reason unknown. */
  path: string; //url/path to go to when clicked, or string for route match
  component: () => JSX.Element; //component to display
  exact?: boolean; //exact only, default false
  urlNotAuthenticated?: string; //url to redirect if not authenticated
  urlIfAuthenticated?: string; //url to redirect to if authenticated, hence path is public only route
}

const AppRouter = () => {
  st.run({ type: "start", path });
  const isAuthenticated = useAuthContextIsLoggedOn();

  // define route
  const routes: IRoute[] = routeData;

  return (
    <Router>
      <div>
        <Switch>
          {routes.map((r, index) => {
            return (
              <MyRoute
                {...r}
                isAuthenticated={isAuthenticated}
                key={"route" + index}
              />
            );
          })}
        </Switch>
      </div>
    </Router>
  );
};

export default AppRouter;
