import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import * as lg from "./logging";

import "./index.scss";

//component
import App from "./1-quarks/routers/App";

//context
import { UserContextProvider } from "./1-quarks/context/User";
import { GlobalContextProvider } from "./1-quarks/context/Global";

//helper
// import st from "./helper/sneaky-ton";
// const path = "/index";

if (process.env.NODE_ENV === "development") {
  lg.development();
}

/* <React.StrictMode></React.StrictMode> */

ReactDOM.render(
  <GlobalContextProvider>
    <UserContextProvider>
      <CssBaseline />
      <App />
    </UserContextProvider>
  </GlobalContextProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
