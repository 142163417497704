import React from "react";

import DashboardTemplate from "5-templates/dashboard";
import { useTradeContextGet, useTagContextGet } from "1-quarks/context/User";
import { filterTradeWithTags } from "1-quarks/helper/trades-filter";

import st from "1-quarks/sneaky-ton";
import BuiltinTags from "0-bonds/data/BuiltinTags";
const path = "/6-pages/dashboard";

const DashboardPage = () => {
  st.run({ type: "start", path });
  const [includes, setIncludes] = React.useState<string[]>([
    BuiltinTags.IS_OPEN,
  ]);
  const [excludes, setExcludes] = React.useState<string[]>([]);
  const trades = filterTradeWithTags(
    filterTradeWithTags(useTradeContextGet(), excludes, false),
    includes,
    true
  );
  const tags = Object.keys(useTagContextGet());
  const props = {
    headerTitle: "Dashboard",
    trades,
    tags,
    includeTags: includes,
    excludeTags: excludes,
    onIncludeValue: (tags: string[]) => setIncludes(tags),
    onExcludeValue: (tags: string[]) => setExcludes(tags),
  };
  return <DashboardTemplate {...props} />;
};

export default DashboardPage;
