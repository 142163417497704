import CryptoJS from "crypto-js";

import st from "./sneaky-ton";
const path = "/1-quarks/encrypt";

/**
 * Encrypt string using AES encryption
 * @param text string to be encrypted
 * @param salt 
 */
export function AesEncrypt(
  text: string | undefined | null,
  salt: string = ""
): string {
  st.run({ type: "start", path, fn: "AesEncrypt" });
  const tmp_str = text || "";
  return CryptoJS.AES.encrypt(tmp_str, salt).toString();
}

/**
 * Decrypt string using AES encryptin
 * @param text string to be decrypted
 * @param salt 
 */
export function AesDecrypt(
  text: string | undefined | null,
  salt: string = ""
): string {
  st.run({ type: "start", path, fn: "AesDecrypt" });
  const tmp_str = text || "";
  return CryptoJS.AES.decrypt(tmp_str, salt).toString(CryptoJS.enc.Utf8);
}

/**
 * hasn string function
 * @param text string to be hashed
 * @param salt 
 */
export function Hash(
  text: string | undefined | null,
  salt: string = ""
): string {
  st.run({ type: "start", path, fn: "Hash" });
  const tmp_str = text || "";
  return CryptoJS.HmacSHA256(tmp_str, salt).toString();
}

export function helperEncrypt(
  text: string | undefined | null,
  uid: string = "",
  password: string = ""
): string {
  return AesEncrypt(text, uid + password);
}

export function helperDecrypt(
  text: string | undefined | null,
  uid: string = "",
  password: string = ""
): string {
  return AesDecrypt(text, uid + password);
}

export function helperHash(
  text: string | undefined | null,
  uid: string = "",
  password: string = ""
): string {
  return Hash(text, uid + password);
}
