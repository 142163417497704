//local
import { IGlobalContextReducerActionAll } from "./interface";
import { IGlobalContextState } from "./context";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/1-quarks/context/Global/reducer";

export const GlobalContextReducer = (
  state: IGlobalContextState,
  action: IGlobalContextReducerActionAll
): IGlobalContextState => {
  st.run({ type: "start", path, fn: "GlobalContextReducer" });
  return state;
  // switch (action.type) {
  //   // case "USERMAP_SET":
  //   //   return {
  //   //     ...state,
  //   //     usermap: action.payload,
  //   //   };
  //   default:
  //     return state;
  // }
};
