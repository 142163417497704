import React from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
// import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/3-molecules/navMenu";

export interface INavLinkProps {
  to: string;
  title: string;
}
export interface INavMenuProps {
  /** List of menu items to pass to NavLink */
  menu: INavLinkProps[];
}

const NavMenu = ({ menu }: INavMenuProps) => {
  st.run({ type: "start", path });
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (v: boolean) => setOpen(v);
  return (
    <React.Fragment>
      <IconButton
        edge="start"
        style={{ marginRight: "8px" }}
        color="inherit"
        aria-label="menu"
        onClick={() => toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>

      <SwipeableDrawer
        anchor={"left"}
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <div
          style={{ width: "250px" }}
          role="presentation"
          onClick={() => toggleDrawer(false)}
          onKeyDown={() => toggleDrawer(false)}
        >
          <List>
            {menu.map((d, i) => (
              <ListItem button component={Link} to={d.to} key={d.title}>
                <ListItemText primary={d.title} />
              </ListItem>
            ))}
          </List>
        </div>
      </SwipeableDrawer>
    </React.Fragment>
  );
};

export default NavMenu;
