import st from "1-quarks/sneaky-ton";
const path = "/1-quarks/helper/calculation";

export function GrossProfit(
  openPrice: number,
  closePrice?: number,
  size = 1,
  directionLong = true
) {
  st.run({ type: "start", path });
  if (
    closePrice === undefined ||
    closePrice === 0 ||
    openPrice === 0 ||
    size === 0
  )
    return 0;
  if (typeof openPrice !== "number") {
    throw new Error("openPrice need to be in float");
  }

  if (typeof closePrice !== "number") {
    throw new Error("closePrice need to be in float");
  }

  if (typeof size !== "number") {
    throw new Error("size need to be in float");
  }

  if (typeof directionLong !== "boolean") {
    throw new Error("directionLong need to be in boolean");
  }

  return (closePrice - openPrice) * size * (directionLong ? 1 : -1);
}

export function NetProfit(profitGross: number, commission?: number) {
  st.run({ type: "start", path });
  return profitGross - (commission || 0);
}

export function RoundUp(num: number, decimalPlace: number): number {
  return parseFloat(num.toFixed(decimalPlace));
}
