import React from "react";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/5-templates/loading";

export interface ILoadingProps {
  children: string;
}

const LoadingTemplate = (props: ILoadingProps) => {
  st.run({ type: "start", path });

  return <div>{props.children}</div>;
};

export default LoadingTemplate;
