/**
 * This class provides a singleton for logging purpose.
 * This is not limit to logging, but to provide a means for executing series of functions
 *
 * When logging, an object with required parameters is needed
 */

/**
 * Execute an array of functions of given name
 * settings:{
 *     'name1': [(props)=>console.log(props)],
 *     'name2': [(props)=>console.log(props)],  *
 * }
 *
 * props:{
 *     'type':'name1',
 *     'other_parameter': 123
 * }
 */
import React, { ReactChild } from "react";

/**
 * this interface is for action function
 */
interface IAction {
  (props: IActionProps): any;
}

type IActionList = IAction[];

interface IActions {
  [key: string]: IActionList;
}

interface IActionProps {
  type: string;
  [key: string]: string;
}

interface IStorage {
  [key: string]: any;
}

type IComponent = JSX.Element | React.Component | React.ReactNode;

class SneakyTon {
  actions: IActions = {};
  storage: IStorage = {};
  DEFAULT_TYPE: string = "-";

  static myInstance: SneakyTon = new SneakyTon();
  /**
   * Get the instance address
   */
  static getInstance(): SneakyTon {
    return SneakyTon.myInstance;
  }

  /**
   * Convert objects to actions in this instance
   * Object should hold list of functions to run
   * Only accept key=>[fn1, fn2]
   * @param {Object} settings
   */
  set(actions: IActions): void {
    this.clear();
    for (const name in actions) {
      if (!Array.isArray(actions[name])) continue; //has to be array

      //loop through all functions in parameter and append
      actions[name].forEach((s) => this.append(name, s)); //add function
    }
  }

  /**
   * Append function to key/name of actions
   * @param {string} name
   * @param {function} fn
   */
  append(name: string, fn: IAction): void {
    if (!(name in this.actions)) {
      this.actions[name] = [];
    }
    this.actions[name].push(fn);
  }
  /**
   * Clear actions
   */
  clear(): void {
    this.actions = {};
  }

  /**
   * run function
   * @param {IActionProps} props props to pass to function, require at least {type:'error'}
   * @return array of results from various functions
   */
  run(props: IActionProps): any[] {
    // if (!(props.type in this.actions)) return []; //do nothing if no setting for given type/name
    // if (this.actions[props.type].length === 0) return []; //do nothing if empty array

    const rtn: any[] = [];
    //provide global variable to props
    const merge_props = { ...this.storage, ...props };

    //loop through object to run all function in array of given type
    if (props.type in this.actions) {
      this.actions[props.type].forEach((f) => {
        rtn.push(f(merge_props));
      });
    } else {
      //if type not defined, assume type="-"
      if (this.DEFAULT_TYPE in this.actions) {
        this.actions[this.DEFAULT_TYPE].forEach((f) => {
          rtn.push(f(merge_props));
        });
      }
    }
    return rtn;
  }

  withTrace = (
    WrappedComponent: React.StatelessComponent<any>,
    name: string
  ): any => (props: any): ReactChild => {
    this.run({ type: "withtrace_start", name });

    return <WrappedComponent {...props} />;
  };
  
  // https://stackoverflow.com/questions/41004631/trace-why-a-react-component-is-re-rendering
  // function useTraceUpdate(props) {
  //   const prev = useRef(props);
  //   useEffect(() => {
  //     const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
  //       if (prev.current[k] !== v) {
  //         ps[k] = [prev.current[k], v];
  //       }
  //       return ps;
  //     }, {});
  //     if (Object.keys(changedProps).length > 0) {
  //       console.log('Changed props:', changedProps);
  //     }
  //     prev.current = props;
  //   });
  // }
  /**
   * replace data in storage
   * @param {string} data data to store in global storage
   */
  setStorage(data: IStorage): void {
    this.storage = data;
  }
  /**
   * save object to global store
   * @param {string} name name to store
   * @param {any} value object, value or function, to store in variable
   */
  appendStorage(name: string, value: any): void {
    this.storage[name] = value;
  }
  /**
   * get object given name
   * @param {string} name name to store
   * @returns object
   */
  getStorage(name: string): any {
    if (name in this.storage) return this.storage[name];
    return undefined;
  }
  /**
   * delete object
   * @param {string} name name to store
   */
  delStorage(name: string): void {
    delete this.storage[name];
  }
  /**
   * Clear object variable
   */
  clearStorage(): void {
    this.storage = {};
  }
}

const st = SneakyTon.getInstance();
export default st;
