import React from "react";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { StandardFormInputProps } from "0-bonds/interface/StandardFormComponent";
//helper
import st from "1-quarks/sneaky-ton";
const path = "/3-molecules/FormLabelText";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "5px 0",
      width: "100%",
    },
    text: {},
  })
);

export interface IFormLabelTextProps extends StandardFormInputProps {
  defaultValue?: string | number;

  error?: boolean;
  pattern?: string;

  //text
  label?: string;
  placeholder?: string;
  helperText?: string;

  onChangeValue?: (e: any) => void;

  //layout
  fullWidth?: boolean;
  multiline?: boolean;
  rows?: number;
  rowsMax?: number;

  //convert output
  shouldParseInt?: boolean;
  shouldParseFloat?: boolean;

  //other checks
  minLength?: number;
  maxLength?: number;
  minNumber?: number;
  maxNumber?: number;

  //other props
  inputProps?: { [key: string]: string | number };
}

const isValid = (
  value: string,
  required: boolean | undefined,
  pattern: string | undefined,
  minLength: number | undefined,
  maxLength: number | undefined,
  minNumber: number | undefined,
  maxNumber: number | undefined
): boolean => {
  if (value.length === 0 && !required) return true;
  //check if pattern exists, if not then continue
  if (pattern !== undefined && pattern.length > 0) {
    //if onChange and pattern exists, check pattern before execute
    const reg = new RegExp(pattern);

    //if pattern doesn't match, exit
    if (!value.match(reg)) {
      return false;
    }
  }

  //check length of value string
  if (minLength !== undefined && value.length < minLength) {
    return false;
  }
  if (maxLength !== undefined && value.length > maxLength) {
    return false;
  }

  //check number range
  if (minNumber !== undefined && parseFloat(value) < minNumber) {
    return false;
  }
  if (maxNumber !== undefined && parseFloat(value) > maxNumber) {
    return false;
  }
  return true;
};

const FormLabelText = ({
  defaultValue,
  onChangeValue,
  pattern,
  minLength,
  maxLength,
  minNumber,
  maxNumber,
  shouldParseInt,
  shouldParseFloat,
  style,
  ...props
}: IFormLabelTextProps) => {
  st.run({ type: "start", path });
  const [localValue, setLocalValue] = React.useState(
    defaultValue?.toString() || ""
  );
  const classes = useStyles();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;

    //check if pattern exists, if not then continue
    if (pattern !== undefined && pattern.length > 0) {
      //if onChange and pattern exists, check pattern before execute
      const reg = new RegExp(pattern);

      //if pattern doesn't match, exit
      if (!val.match(reg)) {
        return;
      }
    }
    if (
      isValid(
        val,
        props.required,
        pattern,
        minLength,
        maxLength,
        minNumber,
        maxNumber
      )
    ) {
      //run onChange function if exists
      if (onChangeValue !== undefined)
        onChangeValue(
          shouldParseInt
            ? parseInt(val)
            : shouldParseFloat
            ? parseFloat(val)
            : val
        );
      //change value locally
      setLocalValue(val);
    }
  };

  //check initial value to see if it matches filter, and highlight error if not
  props.error =
    props.error ||
    !isValid(
      localValue,
      props.required,
      pattern,
      minLength,
      maxLength,
      minNumber,
      maxNumber
    );
  return (
    <FormGroup className={classes.root} style={style}>
      <TextField
        {...props}
        value={localValue}
        onChange={onChange}
        variant="standard"
      />
    </FormGroup>
  );
};

export default FormLabelText;
