//local
import { useUserContextSession } from "./context";
import { IAuthContextPerson } from "./context";

//context
import { AuthContextActionSet, AuthContextActionDel } from "./action";
import { IUserContextDispatch } from "./interface";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/1-quarks/context/User/helper-auth";

/**
 * check if the app has been initialised. This is defined by whether auth is defined or not.
 * @return true if app has been initialised
 */
export const useAuthContextIsInitialised = (): boolean => {
  st.run({ type: "start", path, fn: "useAuthContextIsInitialised" });
  const { UserContext } = useUserContextSession();
  return "auth" in UserContext;
};

/**
 * check if the user has logged on
 * @return true if user has logged on
 */
export const useAuthContextIsLoggedOn = (): boolean => {
  st.run({ type: "start", path, fn: "useAuthContextIsLoggedOn" });
  const { UserContext } = useUserContextSession();
  return "auth" in UserContext && UserContext["auth"] !== null;
};

/**
 * Get user information stored in "auth"
 * @return IAuthContextPerson if logged on, undefined if not initialised, null if logged off
 */
export const useAuthContextCurrentUser = ():
  | undefined
  | IAuthContextPerson
  | null => {
  st.run({ type: "start", path, fn: "useAuthContextCurrentUser" });
  const { UserContext } = useUserContextSession();
  if (!("auth" in UserContext)) return undefined;

  return UserContext["auth"];
};

/**
 * Change value of "auth" to null
 * @param dispatchUserContext dispatcher to change user context
 */
export const helpAuthContextLogout = (
  dispatchUserContext: IUserContextDispatch
) => {
  st.run({ type: "start", path, fn: "helpAuthContextLogout" });
  dispatchUserContext(AuthContextActionDel());
};

/**
 * Save information when user logs in
 * @param dispatchUserContext dispatcher for user context
 * @param fireuser user information returned from firebase
 */
export const helpAuthContextLogin = (
  dispatchUserContext: IUserContextDispatch,
  fireuser: firebase.User
) => {
  st.run({ type: "start", path, fn: "helpAuthContextLogin" });
  dispatchUserContext(
    AuthContextActionSet(AuthContextConvertToPerson(fireuser))
  );
};

/**
 * Convert firebase.user to IAuthContextPerson
 * @param fireuser firebase user
 * @return IAuthContextPerson format saved in UserContext["auth"]
 */
const AuthContextConvertToPerson = (
  fireuser: firebase.User
): IAuthContextPerson => {
  st.run({ type: "start", path, fn: "helpAuthContextConvertToPerson" });
  return {
    _id: fireuser.uid,
    displayName: fireuser.displayName || "",
    photoUrl: fireuser.photoURL || "",
    email: fireuser.email || "",
  };
};
