import React from "react";

import TradeListTemplate from "5-templates/TradeList";
import { useTradeContextGetArray } from "1-quarks/context/User/helper-trades";

//logging
import st from "1-quarks/sneaky-ton";
import { useHistory } from "react-router-dom";
const path = "/6-pages/TradeAdd";

const TradeListPage = () => {
  st.run({ type: "start", path, fn: "main" });
  const trades = useTradeContextGetArray();
  const history = useHistory();
  const columns = [
    { label: "Symbol", value: "symbol" },
    { label: "Size", value: "directionalSize" },
    { label: "Open price", value: "openPrice" },
    { label: "Close price", value: "closePrice" },
    { label: "Net profit", value: "profitNet" },
  ];
  return (
    <TradeListTemplate
      headerTitle="List trades"
      data={trades}
      columns={columns}
      onRowClickedWithId={(id) => history.push("/trade/edit/" + id)}
    />
  );
};
export default TradeListPage;
