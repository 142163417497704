// User context setup
// if used, this will listen to authentication changes during init
// when auth state changes, it will call helper function for login and logout
import React from "react";

import { fireAuth } from "1-quarks/firebase";
import IPerson from "0-bonds/interface/person";
import { ITradeLocalProps } from "0-bonds/interface/trade";

//local
import { IUserContextDispatch } from "./interface";
import { UserContextReducer } from "./reducer";
import { helpAuthContextLogout, helpAuthContextLogin } from ".";

//helper
import st from "1-quarks/sneaky-ton";
import { ISymbols } from "0-bonds/interface/symbol";
import { ITags } from "0-bonds/interface/tag";
const path = "/1-quarks/context/User/context";

///////////////////////interfaces
export interface IAuthContextPerson extends IPerson {
  _id: string; //this uid provided by single sign on
}

export interface ITradeContextTradesProp {
  [key: string]: ITradeLocalProps;
}
export interface IUserContextState {
  /** Keep User information. Created when application is initialized. null if not logged on */
  auth?: null | IAuthContextPerson;
  trades: ITradeContextTradesProp;
  symbols: ISymbols;
  tags: ITags;
}

interface IInitUserContext {
  UserContext: IUserContextState;
  dispatchUserContext: IUserContextDispatch;
}

///////////////////////functions
const userContextContext = React.createContext<IInitUserContext>(
  {} as IInitUserContext
);

/**
 * get user context session for use
 */
export const useUserContextSession = () => {
  st.run({ type: "start", path, fn: "useUserContextSession" });
  return React.useContext(userContextContext);
};

/**
 * Initialise user context
 */
const useUserContextInit = (): IInitUserContext => {
  st.run({ type: "start", path, fn: "useUserContextInit" });

  const [UserContext, dispatchUserContext] = React.useReducer(
    UserContextReducer,
    {
      trades: {},
      tags: {},
      symbols: {},
    }
  );

  //action when changes happen to auth state
  function onChange(user: firebase.User | null): void {
    st.run({ type: "start", path, fn: "useUserContextInit/onChange" });
    if (user === null) {
      //logout
      helpAuthContextLogout(dispatchUserContext);
      return;
    }

    // logs user in
    helpAuthContextLogin(dispatchUserContext, user);
  }

  //listener for auth changes
  React.useEffect(() => {
    st.run({
      type: "start",
      path,
      fn: "useUserContextInit/useEffect subscribe",
    });

    // listen for auth state changes
    const unsubscribe = fireAuth.onAuthStateChanged(onChange);
    // unsubscribe to the listener when unmounting
    return () => unsubscribe();
  }, []);

  return { UserContext, dispatchUserContext };
};

/**
 * Context provider
 * @param props children react node to be enclosed
 */
export const UserContextProvider = (props: {
  children: React.ReactNode;
}): JSX.Element => {
  st.run({ type: "start", path, fn: "UserContextProvider" });
  const value = useUserContextInit();
  // console.log('auth',auth)
  return (
    <userContextContext.Provider value={value}>
      {props.children}
    </userContextContext.Provider>
  );
};
