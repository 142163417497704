import React from "react";

import TradeTable, { ITradeTableProps } from "4-organisms/TradeTable";

import BaseTemplate, { IBaseTemplateProps } from "./base";
import { ITradeLocalProps } from "0-bonds/interface/trade";

//logging
import st from "1-quarks/sneaky-ton";
const path = "/5-templates/TradeList";

export interface ITradeListProps
  extends Omit<IBaseTemplateProps, "children">,
    ITradeTableProps<ITradeLocalProps> {}

const TradeList = (props: ITradeListProps) => {
  st.run({ type: "start", path, fn: "main" });

  return (
    <BaseTemplate headerTitle={props.headerTitle}>
      <TradeTable<ITradeLocalProps> {...props} />
    </BaseTemplate>
  );
};
export default TradeList;
