import useUserContextSession from ".";
import { ISymbols } from "0-bonds/interface/symbol";
import { IUserContextDispatch } from "./interface";
import {
  SymbolsContextActionAdd,
  SymbolsContextActionDel,
  SymbolsContextActionSet,
} from "./action-symbols";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/1-quarks/context/User/helper-symbol";

export const useSymbolContextGet = () => {
  st.run({ type: "start", path, fn: "useSymbolContextGet" });
  const { UserContext } = useUserContextSession();
  if (!("symbols" in UserContext)) return {};

  return UserContext["symbols"];
};

export const helperSymbolContextSet = (
  dispatchUserContext: IUserContextDispatch,
  symbol: ISymbols
) => {
  dispatchUserContext(SymbolsContextActionSet(symbol));
};
export const helperSymbolContextAdd = (
  dispatchUserContext: IUserContextDispatch,
  symbol: ISymbols
) => {
  dispatchUserContext(SymbolsContextActionAdd(symbol));
};

export const helperSymbolContextDel = (
  dispatchUserContext: IUserContextDispatch,
  symbolName: string
) => {
  dispatchUserContext(SymbolsContextActionDel(symbolName));
};
