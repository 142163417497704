//pages
import LoginPage from "6-pages/login";
import TradeAddPage from "6-pages/TradeAdd";
import TradeEditPage from "6-pages/TradeEdit";
import TradeListPage from "6-pages/TradeList";
import DashboardPage from "6-pages/dashboard";
import Err404Page from "6-pages/404";
import { IRoute } from "1-quarks/routers/AppRouter";

const RouteData: IRoute[] = [
  {
    path: "/dashboard",
    component: DashboardPage,
    urlNotAuthenticated: "/",
  },
  {
    path: "/",
    component: LoginPage,
    exact: true,
    urlIfAuthenticated: "/dashboard",
  },
  {
    path: "/trade/add",
    component: TradeAddPage,
    exact: true,
    urlNotAuthenticated: "/",
  },
  {
    path: "/trade/edit/:id",
    component: TradeEditPage,
    exact: true,
    urlNotAuthenticated: "/",
  },
  {
    path: "/trade",
    component: TradeListPage,
    exact: true,
    urlNotAuthenticated: "/",
  },
  {
    path: "/",
    component: Err404Page,
    exact: false,
  },
];

export default RouteData;
