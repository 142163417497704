import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FormBtn, { IFormBtnProps } from "2-atoms/FormBtn";
import FormSelect, { IFormSelectProps } from "3-molecules/FormSelect";
import FormSwitch, { IFormSwitchProps } from "3-molecules/FormSwitch";
import FormDateTime, { IFormDateTimeProps } from "3-molecules/FormDateTime";
import FormLabelText, { IFormLabelTextProps } from "3-molecules/FormLabelText";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/4-organisms/FormBuilder";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "10px",
      marginBottom: "10px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    element: {},
  })
);

interface IFormBuilderBase {
  name: string;
  type: string;
}

interface IFormBuilderButton<T> extends IFormBuilderBase {
  type: "button";
  data: IFormBtnProps;
}
interface IFormBuilderLabelText<T> extends IFormBuilderBase {
  type: "input";
  data: IFormLabelTextProps;
}
interface IFormBuilderSwitch<T> extends IFormBuilderBase {
  type: "boolean";
  data: IFormSwitchProps;
}

interface IFormBuilderDatetime<T> extends IFormBuilderBase {
  type: "datetime" | "time" | "date";
  data: IFormDateTimeProps;
}

interface IFormBuilderSelect<T> extends IFormBuilderBase {
  type: "select";
  data: IFormSelectProps;
}

export type IFormBuilderSetting<T> =
  | IFormBuilderButton<T>
  | IFormBuilderLabelText<T>
  | IFormBuilderSwitch<T>
  | IFormBuilderDatetime<T>
  | IFormBuilderSelect<T>;

export interface IFormBuilderProps<T> {
  /** Setup of the form */
  formJson: IFormBuilderSetting<T>[];
  defaultValues: T;
  onChange: (key: string, value: any) => void;
}

/** Builds form using json. State is managed within the control */
const FormBuilder = <T extends { [key: string]: any }>(
  props: IFormBuilderProps<T>
) => {
  st.run({ type: "start", path });

  const classes = useStyles();

  const onChangeValue = (key: string) => (value: any) => {
    props.onChange(key, value);
  };
  return (
    <div className={classes.root}>
      {props.formJson.map(
        <T extends unknown>(s: IFormBuilderSetting<T>, i: number) => {
          const key = s.name + i.toString();
          if (s.type === "input") {
            return (
              <FormLabelText
                key={key}
                onChangeValue={onChangeValue(s.name)}
                defaultValue={
                  s.name in props.defaultValues
                    ? props.defaultValues[s.name]
                    : undefined
                }
                {...(s.data as IFormLabelTextProps)}
              />
            );
          }

          if (s.type === "boolean") {
            return (
              <FormSwitch
                key={key}
                onChangeValue={onChangeValue(s.name)}
                defaultValue={
                  s.name in props.defaultValues
                    ? props.defaultValues[s.name]
                    : false
                }
                {...(s.data as IFormSwitchProps)}
              />
            );
          }

          if (s.type === "select") {
            return (
              <FormSelect
                key={key}
                onChangeValue={onChangeValue(s.name)}
                defaultValue={
                  s.name in props.defaultValues
                    ? props.defaultValues[s.name]
                    : undefined
                }
                {...(s.data as IFormSelectProps)}
              />
            );
          }

          if (s.type === "datetime" || s.type === "date" || s.type === "time") {
            return (
              <FormDateTime
                key={key}
                onChangeValue={onChangeValue(s.name)}
                type={s.type}
                defaultValue={
                  s.name in props.defaultValues
                    ? props.defaultValues[s.name]
                    : false
                }
                {...(s.data as IFormSwitchProps)}
              />
            );
          }

          if (s.type === "button") {
            return (
              <FormBtn key={key} {...(s.data as IFormBtnProps)}>
                {s.data.children}
              </FormBtn>
            );
          }
          return <div>something missing</div>;
        }
      )}
    </div>
  );
};

export default FormBuilder;
