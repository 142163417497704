import React from "react";

import FormBuilderOrganism, {
  IFormBuilderProps,
} from "4-organisms/FormBuilder";
import { ITradeLocalProps } from "0-bonds/interface/trade";

import BaseTemplate, { IBaseTemplateProps } from "./base";

//logging
import st from "1-quarks/sneaky-ton";
const path = "/5-templates/TradeEdit";

export interface ITradeEditProps
  extends Omit<IBaseTemplateProps, "children">,
    IFormBuilderProps<ITradeLocalProps> {
  onSubmit: () => void;
  isNotFound: boolean;
}

const TradeEdit = (props: ITradeEditProps) => {
  st.run({ type: "start", path, fn: "main" });

  return (
    <BaseTemplate headerTitle={props.headerTitle}>
      {props.isNotFound ? (
        <div>trade not found</div>
      ) : (
        <FormBuilderOrganism<ITradeLocalProps>
          formJson={props.formJson}
          defaultValues={props.defaultValues}
          onChange={props.onChange}
        />
      )}
    </BaseTemplate>
  );
};
export default TradeEdit;
