import { IFormBuilderSetting } from "4-organisms/FormBuilder";
import { ITradeNewProps, ITradeLocalProps } from "0-bonds/interface/trade";
import * as pat from "1-quarks/pattern";

const TradeForm = (
  optSymbol: string[] = ["one"],
  optTags: string[] = [],
  onSubmit: () => void
): IFormBuilderSetting<ITradeNewProps | ITradeLocalProps>[] => {
  return [
    {
      name: "symbol",
      type: "select" as const,
      data: {
        label: "Symbol",
        placeholder: "Symbol of asset",
        multiple: false,
        options: optSymbol,
        selectOnFocus: true,
        clearOnBlur: true,
        handleHomeEndKeys: true,
        freeSolo: true,
        creatable: true,
        filterSelectedOptions: true,
        style: { width: "100%" },
      },
    },
    {
      name: "directionLong",
      type: "boolean" as const,
      data: {
        labelLeft: "Short",
        labelRight: "Long",
        required: true,
      },
    },
    {
      name: "size",
      type: "input" as const,
      data: {
        label: "Position size",
        placeholder: "Quantity purchased. For spreadbetting, 1pt = 100unit",
        required: true,
        pattern: pat.positiveFloat(),
        shouldParseFloat: true,
        inputProps: { type: "number" },
      },
    },
    {
      name: "tags",
      type: "select" as const,
      data: {
        label: "Tags",
        placeholder: "Tag this trade",
        freeSolo: true,
        multiple: true,
        options: optTags,
        creatable: true,
        selectOnFocus: true,
        clearOnBlur: true,
        handleHomeEndKeys: true,
        filterSelectedOptions: true,
      },
    },
    {
      name: "description",
      type: "input" as const,
      data: {
        label: "Description",
        placeholder: "Describe your trade",
        multiline: true,
        rows: 3,
      },
    },
    {
      name: "openPrice",
      type: "input" as const,
      data: {
        label: "Open price",
        placeholder: "The price paid for long/short position",
        required: true,
        pattern: pat.positiveFloat(),
        shouldParseFloat: true,
        inputProps: { type: "number" },
        style: { width: "50%", paddingRight: "5px" },
      },
    },
    {
      name: "openTs",
      type: "datetime" as const,
      data: {
        label: "Open timestamp",
        helperText: "Date time when position opened",
        required: true,
        style: { width: "50%", paddingLeft: "5px" },
      },
    },
    {
      name: "closePrice",
      type: "input" as const,
      data: {
        label: "Close price",
        placeholder: "Closing price",
        required: false,
        pattern: pat.positiveFloat(),
        shouldParseFloat: true,
        inputProps: { type: "number" },
        style: { width: "50%", paddingRight: "5px" },
      },
    },
    {
      name: "closeTs",
      type: "datetime" as const,
      data: {
        label: "Close timestamp",
        helperText: "Date time when position closed",
        required: false,
        style: { width: "50%", paddingLeft: "5px" },
      },
    },
    {
      name: "stopPrice",
      type: "input" as const,
      data: {
        label: "Stop price",
        placeholder: "Stop price",
        required: false,
        pattern: pat.positiveFloat(),
        shouldParseFloat: true,
        inputProps: { type: "number" },
        style: { width: "50%", paddingRight: "5px" },
      },
    },
    {
      name: "stopTs",
      type: "datetime" as const,
      data: {
        label: "Stop timestamp",
        helperText: "Date time when stop price set",
        required: false,
        style: { width: "50%", paddingLeft: "5px" },
      },
    },
    {
      name: "limitPrice",
      type: "input" as const,
      data: {
        label: "Limit price",
        placeholder: "Limit price",
        required: false,
        pattern: pat.positiveFloat(),
        shouldParseFloat: true,
        inputProps: { type: "number" },
        style: { width: "50%", paddingRight: "5px" },
      },
    },
    {
      name: "limitTs",
      type: "datetime" as const,
      data: {
        label: "Limit timestamp",
        helperText: "Date time when limit price set",
        required: false,
        style: { width: "50%", paddingLeft: "5px" },
      },
    },
    {
      name: "commission",
      type: "input" as const,
      data: {
        label: "Commission",
        placeholder: "Commission of trade",
        required: false,
        pattern: pat.floatOnly(),
        shouldParseFloat: true,
        inputProps: { type: "number" },
      },
    },
    {
      name: "submit",
      type: "button" as const,
      data: {
        label: "Save trade",
        onClick: onSubmit,
        variant: "contained",
        color: "primary",
      },
    },
  ];
};
export default TradeForm;
