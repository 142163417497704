//local
import { useGlobalContextSession } from "./context";
import { INavLinkProps } from "3-molecules/navMenu";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/1-quarks/context/Global/helper-menu";

/**
 * get all menu items
 */
export const useMenuContextGet = (): INavLinkProps[] => {
  st.run({ type: "start", path, fn: "useMenuContextGet" });
  const { GlobalContext } = useGlobalContextSession();
  if (!("menu" in GlobalContext)) return [];
  if (GlobalContext["menu"] === undefined) return [];
  return GlobalContext["menu"];
};
