import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import TradeSummary, { ITradeSummaryProps } from "4-organisms/TradeSummary";
import BaseTemplate, { IBaseTemplateProps } from "./base";

//helper
import st from "1-quarks/sneaky-ton";
import { Paper } from "@material-ui/core";
import FilterBar, { IFilterBarProps } from "4-organisms/FilterBar";
const path = "/5-templates/dashbaord";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignContent: "center",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
    },
    paper: { minWidth: "300px", width: "50%", margin: "10px", padding: "10px" },
  })
);

export interface IDashboardProps
  extends Omit<IBaseTemplateProps, "children">,
    ITradeSummaryProps {
  includeTags: string[];
  excludeTags: string[];
  tags: string[];
  onIncludeValue: (value: any | any[], reason: string) => void;
  onExcludeValue: (value: any | any[], reason: string) => void;
}

const DashboardTemplate = (props: IDashboardProps) => {
  st.run({ type: "start", path });
  const classes = useStyles();

  const filterBarProps: IFilterBarProps = {
    tags: props.tags,
    defaultInclude: props.includeTags,
    defaultExclude: props.excludeTags,
    onExcludeValue: props.onExcludeValue,
    onIncludeValue: props.onIncludeValue,
  };

  return (
    <BaseTemplate headerTitle={props.headerTitle}>
      <FilterBar {...filterBarProps} />
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={3}>
          <TradeSummary trades={props.trades} />
        </Paper>
      </div>
    </BaseTemplate>
  );
};

export default DashboardTemplate;
