import React from "react";

//local
import { IGlobalContextDispatch } from "./interface";
import { INavMenuProps } from "3-molecules/navMenu";
import { GlobalContextReducer } from "./reducer";

import MenuData from "0-bonds/data/Menu";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/1-quarks/context/Global/context";

/////////////////////interfaces
export interface IGlobalContextState extends INavMenuProps {}

interface IInitGlobalContext {
  GlobalContext: IGlobalContextState;
  dispatchGlobalContext: IGlobalContextDispatch;
}

/////////////////////functions
const globalContextContext = React.createContext<IInitGlobalContext>(
  {} as IInitGlobalContext
);

export const useGlobalContextSession = () => {
  st.run({ type: "start", path, fn: "useGlobalContextSession" });
  return React.useContext(globalContextContext);
};

//init
const useGlobalContextInit = (): IInitGlobalContext => {
  st.run({ type: "start", path, fn: "useGlobalContextInit" });

  //define default data here
  const [GlobalContext, dispatchGlobalContext] = React.useReducer(
    GlobalContextReducer,
    {
      menu: MenuData,
    }
  );
  return { GlobalContext, dispatchGlobalContext };
};

export const GlobalContextProvider = (props: {
  children: React.ReactNode;
}): JSX.Element => {
  st.run({ type: "start", path, fn: "GlobalContextProvider" });
  const value = useGlobalContextInit();

  return (
    <globalContextContext.Provider value={value}>
      {props.children}
    </globalContextContext.Provider>
  );
};
