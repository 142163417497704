import React from "react";

import FormBuilderOrganism, {
  IFormBuilderProps,
} from "4-organisms/FormBuilder";
import { ITradeNewProps } from "0-bonds/interface/trade";

import BaseTemplate, { IBaseTemplateProps } from "./base";

//logging
import st from "1-quarks/sneaky-ton";
const path = "/5-templates/TradeAdd";

export interface ITradeAddProps
  extends Omit<IBaseTemplateProps, "children">,
    IFormBuilderProps<ITradeNewProps> {
  onSubmit: () => void;
}

const TradeAdd = (props: ITradeAddProps) => {
  st.run({ type: "start", path, fn: "main" });

  return (
    <BaseTemplate headerTitle={props.headerTitle}>
      <FormBuilderOrganism<ITradeNewProps>
        formJson={props.formJson}
        defaultValues={props.defaultValues}
        onChange={props.onChange}
      />
    </BaseTemplate>
  );
};
export default TradeAdd;
