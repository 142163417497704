import React from "react";
import { v4 as uuid } from "uuid";
import { TableHead, TableRow, TableCell } from "@material-ui/core";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/3-molecules/TradeTableHeader";

export interface ITradeTableHeaderColumnProps {
  value: string;
  label: string;
}

export const TradeTableHeaderStrToColumn = (
  s: string | ITradeTableHeaderColumnProps
): ITradeTableHeaderColumnProps => {
  if (typeof s === "string") return { label: s, value: s };
  return s;
};

export interface ITradeTableHeaderProps {
  /** columns to be displayed in header */
  columns: ITradeTableHeaderColumnProps[];
}

const TradeTableHeader = (props: ITradeTableHeaderProps) => {
  st.run({ type: "start", path });

  return (
    <TableHead>
      <TableRow>
        {props.columns.map((c) => (
          <TableCell key={uuid()} variant="head" align="center">
            {c.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TradeTableHeader;
