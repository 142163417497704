import React from "react";
import { v4 as uuid } from "uuid";
import { TableBody, TableRow, TableCell, Chip } from "@material-ui/core";
import { ITradeTableHeaderColumnProps } from "./TradeTableHeader";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/3-molecules/TableBody";

export interface ITradeTableBodyProps<T> {
  data: T[];

  rowsPerPage: number;
  page?: number;

  /** columns to be displayed in header */
  columns: ITradeTableHeaderColumnProps[];
  onRowClickedWithId: (id: string) => void;
}

type ILocalData<T> = Omit<T, "id"> & {
  k: string;
};
const TradeTableBody = <T extends { [key: string]: any }>({
  page = 0,
  rowsPerPage,
  data,
  columns,
  onRowClickedWithId,
}: ITradeTableBodyProps<T>) => {
  st.run({ type: "start", path });
  let count = 0;
  return (
    <TableBody>
      {data
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => {
          count = count + 1;
          return (
            <TableRow key={row.id} onClick={() => onRowClickedWithId(row.id)}>
              {columns.map((c) => {
                let val: any = row[c.value];
                if (Array.isArray(val)) {
                  val = val.map((s) => (
                    <Chip color="primary" size="small" clickable label={s} />
                  ));
                }
                if (typeof val === "boolean") val = val ? "Y" : "N";

                return (
                  <TableCell key={uuid()} align="center">
                    {val}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}

      {count < rowsPerPage && (
        <TableRow style={{ height: 33 * (rowsPerPage - count) }}>
          <TableCell colSpan={columns.length} />
        </TableRow>
      )}
    </TableBody>
  );
};

export default TradeTableBody;
