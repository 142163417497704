import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

//helper
import st from "1-quarks/sneaky-ton";
import { ITradeContextTradesProp } from "1-quarks/context/User/context";
const path = "/4-organisms/TradeSummary";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "5px 0",
      width: "100%",
    },
    title: { fontWeight: "bold", marginBottom: "5px" },
    detail: { marginBottom: "2px" },
  })
);

export interface ITradeSummaryProps {
  trades: ITradeContextTradesProp;
}
const TradeSummary = (props: ITradeSummaryProps) => {
  st.run({ type: "start", path });
  const classes = useStyles();
  const keys = Object.keys(props.trades);
  const vol = keys.reduce((acc, cur) => props.trades[cur].size + acc, 0);
  const netProfitLoss = keys.reduce(
    (acc, cur) => props.trades[cur].profitNet + acc,
    0
  );
  return (
    <div className={classes.root}>
      <div className={classes.title}>Summary</div>
      <div className={classes.detail}>Total trades: {keys.length}</div>
      <div className={classes.detail}>Total volume: {vol}</div>
      <div className={classes.detail}>Closed Net P&amp;L: {netProfitLoss}</div>
    </div>
  );
};

export default TradeSummary;
