// import React from 'react'

//number for currency, allow +/-, one '.'
export function currencyNumber(digit_after_decimal = 2): String {
  // const rtn = "^-?(\d{1,})(\.\d{0," + digit_after_decimal + "})?$"
  const rtn = "^-?\\d{1,}(\\.\\d{0," + digit_after_decimal + "})?$";
  return rtn;
}

export function positiveInt() {
  const rtn = "^\\d*$";
  return rtn;
}

export function negativeInt() {
  const rtn = "^-\\d*$";
  return rtn;
}
export function intOnly() {
  const rtn = "^-?\\d*$";
  return rtn;
}

export function positiveFloat() {
  const rtn = "^\\d{0,}(\\.\\d{0,})?$";
  return rtn;
}

export function negativeFloat() {
  const rtn = "^-\\d{0,}(\\.\\d{0,})?$";
  return rtn;
}
export function floatOnly() {
  const rtn = "^-?\\d{0,}(\\.\\d{0,})?$";
  return rtn;
}
