/**
 * Check if array has duplicate values
 * @param arr array to check
 */
export const arrayIsUnique = (arr: any[]) => {
  return arr.length === new Set(arr).size;
};

/**
 * Remove duplicates from array
 * @param arr array to be converted
 */
export const arrayRemoveDuplicates = (arr: any[]) => {
  return Array.from(new Set(arr));
};

/**
 * Convert an array of string to object using string as keys
 * @param arr array of string as object key
 * @param value value to put in object, uses index if undefined
 */
export const arrayToObjectKey = (arr: string[], value: any) => {
  const obj: { [key: string]: any } = {};
  arr.forEach((v: string, i: number) => {
    obj[v] = value || i;
  });
  return obj;
};
