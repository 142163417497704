import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import HeaderComp, { IHeaderCompProps } from "6-pages/components/header";

//logging
import st from "1-quarks/sneaky-ton";
const path = "/5-templates/TradeList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignContent: "center",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    body: { maxWidth: "800px", width: "80%", margin: "20px 0" },
  })
);

export interface IBaseTemplateProps extends IHeaderCompProps {
  children: React.ReactNode;
}

const BaseTemplate = (props: IBaseTemplateProps) => {
  st.run({ type: "start", path, fn: "main" });
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HeaderComp headerTitle={props.headerTitle} />
      <div className={classes.body}>{props.children}</div>
    </div>
  );
};
export default BaseTemplate;
