import { IObjectAny } from "0-bonds/interface/object";

/**
 * Checks if both object has the same property
 * @param objA object to check
 * @param objB object to check
 * @returns true if they have matching property key
 */
export const objectHasMatchProperty = (
  objA: IObjectAny,
  objB: IObjectAny
): boolean => {
  return objectHasKeys(objA, Object.keys(objB));
};

/**
 * Check whether object has certain keys
 * @param obj object to check
 * @param findKeys list of keys to find object
 * @returns true if any of the keys are found in object
 */
export const objectHasKeys = (obj: IObjectAny, findKeys: string[]) => {
  for (const k in findKeys) {
    if (obj.hasOwnProperty(k)) return true;
  }
  return false;
};

/**
 * Get length of object based on number of keys
 * @param obj Object to find length
 */
export const objectLength = (obj: IObjectAny) => {
  return Object.keys(obj).length;
};

/**
 * remove keys that exists in the list of string
 * @param obj object to be checked
 * @param removeKeys list of keys to remove
 */
export const objectRemoveKeys = (
  obj: IObjectAny,
  removeKeys: string[]
): IObjectAny => {
  const currentKeys = Object.keys(obj);
  const result: IObjectAny = {};
  const filteredKeys = currentKeys.filter((k) => removeKeys.indexOf(k) < 0);
  filteredKeys.forEach((k) => (result[k] = obj[k]));
  return result;
};

/**
 * keep only keys that exists in the list of string
 * @param obj object to be checked
 * @param keepKeys list of keys to remove
 */
export const objectKeepKeys = (
  obj: IObjectAny,
  keepKeys: string[]
): IObjectAny => {
  const currentKeys = Object.keys(obj);
  const result: IObjectAny = {};
  const filteredKeys = currentKeys.filter((k) => keepKeys.indexOf(k) >= 0);
  filteredKeys.forEach((k) => (result[k] = obj[k]));
  return result;
};
