/**
 * user context holds all data for a user
 */

import {
  useUserContextSession,
  // useUserContextInit,
  UserContextProvider,
  IAuthContextPerson,
} from "./context";

import {
  IUserContextDispatch,
  IUserContextReducerActionAll,
} from "./interface";
import {
  useAuthContextIsInitialised,
  useAuthContextIsLoggedOn,
  useAuthContextCurrentUser,
  helpAuthContextLogout,
  helpAuthContextLogin,
} from "./helper-auth";

import {
  useSymbolContextGet,
  helperSymbolContextSet,
  helperSymbolContextAdd,
  helperSymbolContextDel,
} from "./helper-symbols";
import {
  useTagContextGet,
  helperTagContextSet,
  helperTagContextAdd,
  helperTagContextDel,
} from "./helper-tags";
import {
  useTradeContextGet,
  helperTradesAdd,
  helperTradesSubscribe,
  helperTradesUnsubscribe,
} from "./helper-trades";

export {
  // useUserContextInit,
  UserContextProvider,
  useAuthContextIsInitialised,
  useAuthContextIsLoggedOn,
  useAuthContextCurrentUser,
  helpAuthContextLogout,
  helpAuthContextLogin,
  useSymbolContextGet,
  helperSymbolContextAdd,
  helperSymbolContextSet,
  helperSymbolContextDel,
  useTagContextGet,
  helperTagContextAdd,
  helperTagContextSet,
  helperTagContextDel,
  useTradeContextGet,
  helperTradesAdd,
  helperTradesSubscribe,
  helperTradesUnsubscribe,
};

export type {
  IAuthContextPerson,
  IUserContextDispatch,
  IUserContextReducerActionAll
};

export default useUserContextSession;
