import React from "react";

import Button from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import IComponent from "0-bonds/interface/Component";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/2-atoms/FormBtn";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "5px 0",
    },
  })
);
export interface IFormBtnProps extends IComponent {
  /** String to show on the button */
  children?: React.ReactNode;
  /** Label string similar to children */
  label?: string;

  /** Set width of button */
  fullWidth?: boolean;
  /** Disable button */
  disabled?: boolean;
  /** icon at beginning */
  startIcon?: React.ReactNode;
  /** icon on end */
  endIcon?: React.ReactNode;

  /** Different variant for different colour */
  color?: "default" | "inherit" | "primary" | "secondary";
  variant?: "contained" | "outlined" | "text";

  /** Click event on button */
  onClick?: () => void;
}

const FormBtn = (props: IFormBtnProps) => {
  st.run({ type: "start", path });

  const classes = useStyles();
  const { children, ...rest } = props;
  return (
    <Button className={classes.root} {...rest}>
      {props.children}
      {props.label}
    </Button>
  );
};

export default FormBtn;
