import { ITags } from "./tag";

/**
 * Information stored for a new trade before it is added
 */
export interface ITradeNewProps
  extends Omit<ITradeModelProps, "uid" | "createTs" | "updateTs"> {}

/**
 * model for a trade stored in db
 */
export interface ITradeModelProps {
  /** Trade id */
  id?: string;

  /** User id/owner of trade */
  uid: string;

  /** Timestamp of trade created */
  createTs: string;

  /** Timestamp of trade last updated */
  updateTs: string;

  /** Trade symbol */
  symbol: string;

  /** Direction of trade, true if long, false if short */
  directionLong: boolean;

  /** Size of trade, positive float allowed */
  size: number;

  /** List of tags, key is tag name */
  tags: ITags;

  /** Price when trade opens */
  openPrice: number;

  /** Open timestamp in ISO format */
  openTs: string;

  /** Price when trade closes, 0 means trade still active */
  closePrice?: number;

  /** Close timestamp in ISO format */
  closeTs?: string;

  /** Stop Price */
  stopPrice?: number;

  /** Stop price timestamp in ISO format */
  stopTs?: string;

  /** Limit Price */
  limitPrice?: number;

  /** Limit price timestamp in ISO format */
  limitTs?: string;

  /** Commission of trade if known */
  commission?: number;

  /** Description of the trade, or notes */
  description?: string;
}

/**
 * Trade data stored locally including calculation
 */
export interface ITradeLocalProps extends ITradeModelProps {
  /** Calculated gross profit */
  profitGross: number;

  /** Calculated net profit */
  profitNet: number;

  /** Position size direction is directional sizing, negative for short, positive for long */
  directionalSize: number;
}

export const defaultTradeNew: ITradeNewProps = {
  symbol: "",
  directionLong: true,
  size: 0,
  tags: {},
  openPrice: 0,
  openTs: "",
};

export const defaultTradeModel: ITradeModelProps = {
  uid: "",
  createTs: "",
  updateTs: "",
  ...defaultTradeNew,
};

export const defaultTradeLocal: ITradeLocalProps = {
  profitGross: 0,
  profitNet: 0,
  directionalSize: 0,
  ...defaultTradeModel,
};
