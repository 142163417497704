import React from "react";

import { fireAuth, fireapp, firebaseUi } from "1-quarks/firebase";
import LoginTemplate, { ILoginProps } from "5-templates/login";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/6-pages/login";

const LoginPage = () => {
  st.run({ type: "start", path });
  const d: ILoginProps = {
    pageTitle: process.env.REACT_APP_SITE_TITLE || "",
    firebaseAuth: fireAuth,
    firebaseAuthUiConfig: {
      // Popup signin flow rather than redirect flow.
      signInFlow: "popup",
      credentialHelper: firebaseUi.auth.CredentialHelper.NONE,
      // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
      // signInSuccessUrl: "/dashboard",
      // We will display Google and Facebook as auth providers.
      signInOptions: [
        fireapp.auth.EmailAuthProvider.PROVIDER_ID,
        fireapp.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
      callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false,
      },
    },
  };

  return <LoginTemplate {...d} />;
};

export default LoginPage;
