import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

//components
import NavMenu, { INavMenuProps } from "3-molecules/navMenu";
import NavUser, { INavUserProps } from "3-molecules/navUser";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/4-organisms/header";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "100%",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      display: "inline",
    },
  })
);
export interface IHeaderProps extends INavUserProps, INavMenuProps {
  headerTitle: string;
}

const Header = ({ menu, user, headerTitle, onLogoutClick }: IHeaderProps) => {
  st.run({ type: "start", path });
  // return (
  //   <Navbar expand={"sm"} bg="dark" variant="dark">
  //     <NavBrand to={brandLink} />
  //     <Navbar.Collapse>
  //       <NavMenu menu={menu} />
  //     </Navbar.Collapse>
  //     <NavUser user={user} onLogoutClick={onLogoutClick} />
  //   </Navbar>
  // );
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <NavMenu menu={menu} />

          <Typography variant="h6" style={{ display: "inline", flexGrow: 1 }}>
            {headerTitle}
          </Typography>
          <NavUser user={user} onLogoutClick={onLogoutClick} />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
